import { stripeKey, stripeSGKey } from '@/configs/defaultValues';
import getSymbolFromCurrency from 'currency-symbol-map';

const { store } = require('@/services');

export const getStoreInfo = async (id, mid, bid, lng, lat) => {
  const storeInfo = id
    ? await store.findById(id, lng, lat)
    : await store.find(mid, bid, lng, lat);

  storeInfo.displayName = `${storeInfo.merchantName} - ${storeInfo.branchName}`;
  const specialBrand = await store.configValue(
    storeInfo.merchantId,
    'special_brand',
    'oaw'
  );
  if (specialBrand) {
    const { value } = specialBrand;
    const specialBrandValue = JSON.parse(value);
    if (Object.keys(specialBrandValue).indexOf(storeInfo.branchId) > -1) {
      storeInfo.logo = specialBrandValue[storeInfo.branchId].logo;
      storeInfo.displayName = specialBrandValue[storeInfo.branchId].name;
    }
  }
  sessionStorage.setItem('__currency', storeInfo.currency);
  sessionStorage.setItem('__region', storeInfo.iso2);
  return storeInfo;
};

export const getRegion = () => {
  return sessionStorage.getItem('__region') ?? 'AU';
};

export const getCurrency = () => {
  return sessionStorage.getItem('__currency') ?? 'AUD';
};

export const getCurrencySymbol = () => {
  return getSymbolFromCurrency(getCurrency());
};

export const getStripeKeyIntent = () => {
  const region = getRegion();
  if (region === 'SG') return 2;
  return 0;
};
export const getStripeKey = () => {
  const region = getRegion();
  if (region === 'SG') return stripeSGKey;
  return stripeKey;
};

export const getMerchantInfo = () => {};
